import { LocalStorageServiceInterface } from './local-storage-service.interface';
import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService implements LocalStorageServiceInterface {
  private prefix = 'p500:';
  private localStorage: any = localStorage;

  setItem(key: string, value: any): void {
    const convertedValue = JSON.stringify(value);
    this.localStorage.setItem(this.prefix + key, convertedValue);
  }

  getItem(key: string): any {
    const value = this.localStorage.getItem(this.prefix + key);

    try {
      return JSON.parse(value);
    } catch (e) {}

    return value;
  }

  removeItem(key: string): void {
    this.localStorage.removeItem(this.prefix + key);
  }

  clear(): void {
    this.localStorage.clear();
  }
}
