import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AuthService } from './auth/auth.service';
import { BaseModalComponent } from './modals/base-modal.component';
import { BrowserWindowRef } from './window/window.service';
import { ChargifyService } from './chargify/chargify.service';
import { CommonModule } from '@angular/common';
import { ConfigService } from './config/config.service';
import { DataService } from './data/data.service';
import { EventsService } from './events/events.service';
import { JwtInterceptor } from '../interceptors/jwt.interceptor';
import { LocalStorageService } from './local-storage/local-storage.service';
import { ModalsService } from './modals/modals.service';
import { NgModule } from '@angular/core';
import { NotificationsService } from './notifications/notifications.service';
import { UserResolverService } from './auth/user.resolver.service';
import { GeoService } from './geo/geo.service';
import { AsyncValidatorService } from './async-validator/async-validator.service';

@NgModule({ declarations: [BaseModalComponent],
    exports: [BaseModalComponent], imports: [CommonModule], providers: [
        UserResolverService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
        {
            provide: 'AuthServiceInterface',
            useClass: AuthService,
        },
        {
            provide: 'ConfigServiceInterface',
            useClass: ConfigService,
        },
        {
            provide: 'LocalStorageServiceInterface',
            useClass: LocalStorageService,
        },
        {
            provide: 'EventsServiceInterface',
            useClass: EventsService,
        },
        {
            provide: 'NotificationsServiceInterface',
            useClass: NotificationsService,
        },
        {
            provide: 'ModalsServiceInterface',
            useClass: ModalsService,
        },
        {
            provide: 'IChargifyService',
            useClass: ChargifyService,
        },
        {
            provide: 'IDataService',
            useClass: DataService,
        },
        {
            provide: 'GeoServiceInterface',
            useClass: GeoService,
        },
        {
            provide: 'IAsyncValidatorService',
            useClass: AsyncValidatorService,
        },
        BrowserWindowRef,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class ServicesModule {}
