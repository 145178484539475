import { Inject, Injectable } from '@angular/core';
import { IData, IDataResponse, IDataService } from './data-service.interfaces';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ConfigServiceInterface } from '../config/config-service.interface';
import { DataServiceConstants } from './data.constants';

@Injectable()
export class DataService implements IDataService {
  private data: IData;
  private dataServiceConstants = DataServiceConstants;

  constructor(private http: HttpClient, @Inject('ConfigServiceInterface') private configService: ConfigServiceInterface) {}

  setData(data: IData): void {
    this.data = data;
  }

  private parseData(origin: IDataResponse) {
    const data: IData = {
      countries: {},
      billingProducts: {},
      templates: [],
    };

    data.countries = this.dataServiceConstants.countries;
    data.billingProducts = origin.data.billing_products;
    data.templates = origin.data.templates;

    return data;
  }

  getDataFromServer(): Observable<IData> {
    return this.http.post<IDataResponse>(`${this.configService.apiUrlV2}/data`, { types: ['billing_products', 'templates'] }).pipe(
      map((response) => {
        return this.parseData(response);
      })
    );
  }

  getData(): IData {
    return this.data;
  }
}
