import { Inject, Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, mergeMap, take } from 'rxjs/operators';
import { UserResponse } from './responses/user.response';
import { LocalStorageServiceInterface } from '../local-storage/local-storage-service.interface';
import { AuthServiceInterface } from './auth-service.interface';

@Injectable()
export class UserResolverService  {
  constructor(
    @Inject('LocalStorageServiceInterface') private localStorageService: LocalStorageServiceInterface,
    @Inject('AuthServiceInterface') private authService: AuthServiceInterface
  ) {}

  resolve(): Observable<UserResponse> {
    const userData = this.authService.getUserData();

    if (userData) {
      return of(userData);
    } else {
      return this.authService.getUser().pipe(
        take(1),
        mergeMap((user) => {
          if (user) {
            return of(user);
          } else {
            this.localStorageService.removeItem('token');
            return of(null);
          }
        }),
        catchError(() => {
          this.localStorageService.removeItem('token');
          return of(null);
        })
      );
    }
  }
}
