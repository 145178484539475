import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageServiceInterface } from '../services/local-storage/local-storage-service.interface';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(@Inject('LocalStorageServiceInterface') private localStorageService: LocalStorageServiceInterface) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.localStorageService.getItem('token');
    const authRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });

    return next.handle(authRequest);
  }
}
