import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ServicesModule } from './core/services/services.module';
import { StateStepsService } from './modules/wizard/services/state-steps/state-steps.service';
import { IMODALS_SERVICE, ModalsService } from '@platform500services/p500-ui-kit';

import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { ErrorHandlerService } from './core/services/error-handler/error-handler.service';

@NgModule({
  imports: [BrowserModule, AppRoutingModule, ServicesModule],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: 'IStateSteps',
      useClass: StateStepsService,
    },
    {
      provide: IMODALS_SERVICE,
      useClass: ModalsService,
    },
  ],
})
export class AppModule {}
