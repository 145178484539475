import { ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/angular';

export class ErrorHandlerService implements ErrorHandler {
  private sentryErrorHandler = Sentry.createErrorHandler({
    showDialog: false,
  });

  public handleError(error: any): void {
    if (String(error?.message).includes(`'transaction' on 'IDBDatabase'`)) {
      window.location.reload();
      return;
    }
    this.sentryErrorHandler.handleError(error);
  }
}
