import { getParameterByName } from './get-parameter-by-name.function';

export function redirectAfterLogin(data) {
  const redirect = getParameterByName('redirect');
  if (redirect && data?.token) {
    const url = new URL(redirect);
    url.searchParams.set('token', data.token);
    window.location.href = url.toString();
  }
}
