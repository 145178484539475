import { BehaviorSubject, Observable, timer } from 'rxjs';

import { Injectable } from '@angular/core';
import { NotificationsServiceInterface } from './notifications-service.interface';

@Injectable()
export class NotificationsService implements NotificationsServiceInterface {
  private notifications: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private subscribers: any = {};

  notificationsObservable(): Observable<any[]> {
    return this.notifications.asObservable();
  }

  add(notification, closeAfterShow: boolean = true): void {
    const notifications = this.notifications.getValue();
    if (typeof notification.title === 'undefined') {
      if (notification.type === 'error') {
        notification.title = 'Something went wrong.';
      }
      if (notification.type === 'success') {
        notification.title = 'The action was completed successfully.';
      }
    }
    notifications.unshift(notification);
    this.notifications.next(notifications);
    if (closeAfterShow) {
      this.removeLastAfter(10000);
    }
  }

  remove(notificationIndex): void {
    const notifications = this.notifications.getValue();
    notifications[notificationIndex].class = 'is-closing';
    this.notifications.next(notifications);
    timer(150).subscribe(() => {
      notifications.splice(notificationIndex, 1);
      this.notifications.next(notifications);
    });
  }

  removeLastAfter(interval: number): void {
    this.subscribers.timer = timer(interval).subscribe(() => {
      const currentNotifications = this.notifications.getValue();
      const index = currentNotifications.length - 1;
      if (index >= 0) {
        this.remove(index);
      }
    });
  }

  unsubscribe(): void {
    this.notifications.next([]);
  }
}
