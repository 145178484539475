import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { ModalsServiceInterface } from './modals-service.interface';

@Injectable()
export class ModalsService implements ModalsServiceInterface {
  static counter = 0;
  static modals: number[] = [];
  target;
  targetContainer;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  open(modal: any, data: object): void {
    if (!ModalsService.modals.length) {
      this.target.element.nativeElement.classList.add('is-overlay');
      document.querySelector('html').classList.add('is-overlay');
    }
    const modalId = ModalsService.counter + 1;
    ModalsService.counter += 1;
    ModalsService.modals.push(modalId);
    const modalFactory = this.componentFactoryResolver.resolveComponentFactory(modal);
    const modalReference = this.targetContainer.createComponent(modalFactory);
    modalReference.instance.id = modalId;
    modalReference.instance.data = data;
    modalReference.instance.modal = modalReference;
    modalReference.changeDetectorRef.detectChanges();
  }

  close(modalId: number): void {
    const modalIndex = ModalsService.modals.indexOf(modalId);
    if (modalIndex !== -1) {
      ModalsService.modals.splice(modalIndex, 1);
    }
    if (!ModalsService.modals.length) {
      this.target.element.nativeElement.classList.remove('is-overlay');
      document.querySelector('html').classList.remove('is-overlay');
    }
  }
}
