import { Inject, Injectable } from '@angular/core';
import { ConfigServiceInterface } from '../config/config-service.interface';
import { HttpClient } from '@angular/common/http';
import { GeoServiceInterface, ICountryResponse } from './geo-service.interface';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class GeoService implements GeoServiceInterface {
  constructor(@Inject('ConfigServiceInterface') private configService: ConfigServiceInterface, private http: HttpClient) {}

  public data(): Observable<ICountryResponse> {
    return this.http.get(`${this.configService.apiUrlV2}/geo`).pipe(
      map((response: ICountryResponse) => {
        return response;
      })
    );
  }
}
