import { Inject, Injectable } from '@angular/core';
import { BrowserWindowRef } from '../window/window.service';
import { IChargifyService, IChargify } from './chargify-service.interface';

@Injectable()
export class ChargifyService implements IChargifyService {
  private chargifyApi: IChargify;

  constructor(@Inject(BrowserWindowRef) private window: BrowserWindowRef) {
    const nativeWindow = this.window.nativeWindow as any;
    if (nativeWindow.Chargify) {
      this.chargifyApi = new nativeWindow.Chargify();
    }
  }

  get chargify(): IChargify {
    return this.chargifyApi;
  }
}
