import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { NotificationsServiceInterface } from '../../../core/services/notifications/notifications-service.interface';
import { ConfigServiceInterface } from '../../../core/services/config/config-service.interface';
import { IAsyncValidatorService, IValidator } from './async-validator-service.interface';

@Injectable()
export class AsyncValidatorService implements IAsyncValidatorService {
  constructor(
    @Inject('ConfigServiceInterface') private configService: ConfigServiceInterface,
    @Inject('NotificationsServiceInterface') public notificationsService: NotificationsServiceInterface,
    private http: HttpClient
  ) {}

  public AsyncValidator(value: IValidator): Observable<any> {
    return this.http.post(`${this.configService.apiUrlV2}/validate`, value).pipe(
      map(() => null),
      catchError((error: HttpErrorResponse) => {
        const errors = error.error.errors;
        if (errors.phone) {
          return of({ msg: errors.phone[0] });
        }
        if (errors.email) {
          return of({ msg: errors.email[0] });
        }
        return null;
      })
    );
  }
}
